.legend-1, .legend-2, .legend-3, .legend-4 {
  width: 20px;
  height: 5px;
  border-radius: 30px;
  display: inline-block;
  background: gray;
  margin-bottom: 2px;
}
.legend-1 {
  background-color: #6610f2;
}
.legend-2 {
  margin-left: 25px;
  background-color: #87f5fb;
}
.legend-3 {
  margin-left: 25px;
  background-color: #eb4adf;
}
.legend-4 {
  margin-left: 25px;
  background-color: #FDC600;
}

.btn.btn-primary,
.btn.btn-primary:hover:not(.btn-active),
.btn.btn-primary:active:not(.btn-active) {
  background-color: #6610f2 !important;
  border-color: #6610f2 !important;
}

.text-active-primary.active {
  color: #6610f2 !important;;
}

.nav-line-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #6610f2 !important;
}

.bg-primary {
  background-color: #9459f6 !important;
}

.bg-success {
  background-color: #6610f2 !important;
}
